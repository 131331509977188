<template>
  <div style="height: 0px">
  <div style="position: relative; top: 0px;text-align: center;border:" hidden>
    <div id="divArtist" style="font-size: 14px; color: black;font-weight: 600">The Beatles</div>            
    <div id="divAlbum" style="font-size: 14px; color: black;font-weight: 600">Meet The Beatles</div>            
    <div id="divTrackTitle" style="font-size: 14px; color: black;font-weight: 600">I Saw Her Standing There</div>            
  </div>
  <div class="container" style="position: relative; top: -505px;text-align: center;" hidden>            
    <div class="music-player" >      
      <nav>        
        <div class="rectangle">
          <span onclick="window.scrollBy(0,-130)" class="material-symbols-outlined" style="border: solid grey 0px;width: 35px;height: 135px;padding-top: 70px;margin-left: -20px">
            chevron_left
            </span>
        </div>
        <div class="rectangle">
          <span onclick="window.scrollBy(0,130)" class="material-symbols-outlined" style="border: solid grey 0px;width: 35px;height: 135px;padding-top: 70px;margin-left: 10px">
            chevron_right
            </span>
        </div>
      </nav>
      
      <audio id="song" class="controls" style="display: flex;">
                     
      <source src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/Beatles%20-%20I%20Saw%20Her%20Standing%20There.mp3?alt=media&token=a13b0174-dee3-40d6-aeb9-ab183014cbd1" type="audio/mpeg">
      </audio>      
    </div>
  </div>  
  
  <div class="controls w-26rem" style="position: absolute;margin: auto;top: 686px;justify-content: center;z-index: 1;border: solid black 0px;" hidden>           
    <div class="material-symbols-outlined" style="margin: 1px;background-color: rgba(234, 99, 46, 0.985);color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
      fast_rewind
    </div>
    <div class="material-symbols-outlined" style="margin: 1px;background-color: rgba(114, 124, 170, 0.89);color: rgb(1, 0, 0);border: solid black 1px;text-align: center;">
      <i onclick="playPause()" id="ctrlIcon" class="material-symbols-outlined">play_circle</i>
    </div>
    <div class="material-symbols-outlined" style="margin: 1px;background-color:rgba(145, 177, 120, 0.996);color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
      fast_forward
    </div>
  </div>
  <input type="range" value="0" id="progress" style="position: relative;top: -215px;width: 50%;height: 20px;border: solid black 1px;display: block;margin: auto;z-index: 2;background-color: grey;" hidden>                                
</div>
</template>

<script>
export default {
  
}

</script>

<style>
      *{
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        box-sizing: border-box; 
      }  
      .container {
        position: relative;
        border: solid black 0px; 
        height: 70px; 
        margin-top: 0px;   
        z-index: 1;   
      }
      .music-player {
        margin: auto;
        position: relative;   
        margin-top: 0px;     
        background: #ffffffea;        
        border: solid black 0px;
        z-index: 1;
        width: 50%;      
        background-color: transparent
      }
      nav {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 115px;
      }

      nav .rectangle {
        border-top-left-radius: 40%;
        width: 35px;
        height: 150px;
        line-height: 45px;
        background-color: transparent;
        color: #fffffe;          
        margin-top: -215px;
        border: solid rgb(0, 0, 0) 0px;
        box-shadow: 0px 0px 0px;
        padding-top: 0px;
      }
      nav .search {
        border-top-left-radius: 0%;
        line-height: 0px;
        background-color: transparent;
        color: #fffffe;          
        margin-top: -326px;
        border: solid rgb(0, 0, 0) 0px;
        box-shadow: 0px 0px 0px;
        padding-top: 0px;
      }
      .song-img {
        width: 225px;
        border-radius: 0%;
        border: 1px solid #000;         
      }
      .music-player h1{
        font-size: 20px;
        font-weight: 800;
        color: #333;
        margin-top: 0px;
      }
      .music-player p{
        font-size: 14px;       
        color: #908484;
        margin-top: 60px;
      }
      #progress {
        -webkit-appearance: none;
        width: 100%;
        height: 6px;
        background: #d3cfd3ea;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      #progress::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #c77cc3fc;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 10px solid #444;
      
      }
      .controls {
        display: flex;
        margin: auto;
        justify-content: space-between;        
        width: 50%;
        margin-top: 20px;
      }
      .controls  div{                
        border-radius: 10%;
        width: 35px;
        background: #ae89ad00;
        cursor: pointer;        
      }      
      .controls  div:nth-child(1){
        transform: scale(1);
        color: #000000;
        height: 35px;
        padding-top: 5px;
        padding-right: 3px;
        box-shadow: 0px 0px 0px rgb(229, 151, 6);
      }
      .controls  div:nth-child(2){
        transform: scale(1);
        background: rgba(211, 101, 200, 0);
        color: #020000;
        height: 35px;
        padding-top: 5px;
        box-shadow: 0px 0px 0px rgb(26, 129, 160);  
      }
      .controls  div:nth-child(3){
        transform: scale(1);
        color: #000000;
        height: 35px;
        padding-top: 5px;
        padding-left: 3px;
        box-shadow: 0px 0px 0px rgb(28, 175, 23);
      }  
</style>