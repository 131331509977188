var fs = require('fs');
//var obj = JSON.parse(fs.readFileSync('./src/assets/json/youtubes.json', 'utf8'));
//const jsonFileName = './src/assets/json/dj.json';
//const file = require(jsonFileName);
//var obj = JSON.parse(fs.readFileSync('jsconfig.json', 'utf8'));
 

console.log("Resetting genre to []");
//for(var i=0;i<file.Youtubes.length;i++) {
//  file.Youtubes[i].genre = ["All"];
//  file.Youtubes[i].member = ["Mario"];
//} 



function test() {
  console.log("Test");
}
test();

/*
fs.writeFile(jsonFileName, JSON.stringify(file, null, 2), function writeJSON(err) {
if (err) return console.log(err);
    console.log(JSON.stringify(jsonFileName, null, 2));
    console.log('writing to ' + jsonFileName);
});
*/
