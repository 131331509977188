
<template> 
  <div class="w-full" v-if="user" id="divNav" style="display: flex;margin: auto;justify-content: center;margin-bottom: 10px;border: solid black 0px">      
    
    <div style="border: solid black 0px" class="third">
      <label v-if="user" style="color: purple;font-size: 16px;margin-right: 10px">

        </label>
    </div>            
    
    <div style="border: solid black 0px;justify-content: center;" class="third">
      <img v-if="isMobile() == true" src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jookbox.png?alt=media&token=5b6174a2-b40b-4523-82ac-ff3adebe8f01"      
        style="border: solid black 0px;padding: 0px;width: 75%" alt="">   
      <img v-else src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jookbox.png?alt=media&token=5b6174a2-b40b-4523-82ac-ff3adebe8f01"      
        style="border: solid black 0px;padding: 0px;width: 75%" alt="">         
    </div> 

    <div style="margin: auto;border: solid black 0px;justify-content: right;margin-top: -10px;height: 30px;" class="third">      
        <button class="btn btn-primary logout" @click="handleClick" style="padding-top: 3px">Logout</button>      
    </div>    

    <!-- Navigation -->
    
  </div>
</template>

<script>
import { ref } from 'vue'
import { useToast } from "primevue/usetoast";
import useLogout from '@/composables/useLogout';
import getUser from '@/composables/getUser';
import router from '../router'

export default {
  name: "apps",
      mounted: function() {
        this.isMobile();        
      },    
      methods: {  
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                console.log("Is Mobile = true");
                return true
            } else {
                console.log("Is Mobile = false");
                return false
            }
        }     
      },
      data() {
        return {
          reactive: true,
          onChange(e) {
            console.log("onChange: " + e);
          },
          onFocus(e) {
            console.log("onFocus: " + e);
            //inpSearch.classList.remove("hidden")
            $("#divTitle").css("background-color","#ffffe0")
          },
          onBlur(id) {
            console.log("Title: " + $("#"+id).html());
            English[0] = { Title: $("#"+id).html() };
            //$("#inpSearch").css("background-color","white")            
          },
          handleCollapse(e) {
            console.log("onBlur: " + e);
            //$("#inpSearch").css("background-color","white")
          }  
        };
      },
      computed: {             
      },
  el: '#app',
  setup() {
    const toast = useToast()
    const active = ref(0);
    const { user } = getUser()
    const { logout } = useLogout()


    const handleClick = async () => {
      await logout()
      console.log('user logged out')
      router.push({ name: 'Login' })
      //location.reload()
      $("#divNav").hide()
    }

    const handleSearch = async () => {
      console.log('handleSearch')
    }

    const isMobile = () => {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          //console.log("Is Mobile: true");
          return true
      } else {
          //console.log("Is Mobile: false");
          return false
      }
    }

    return { isMobile, handleClick, handleSearch, user, active, toast }    
  }
}
</script>

<style> 

.center {
  display: flex;
  margin: auto;
  border: solid black 0px;  
  justify-content: center;
}

.img {
  display: flex;
  margin: auto;
  border: solid black 0px;
  border-radius: 8px;
  justify-content: center;
  width: 50%;
  padding: 5px;
  margin-bottom: 10px;
}

.nowrap {
  display: flex;
  margin: auto;
  flex-wrap: nowrap;
  border: solid black 0px;  
  justify-content: center;
  width: 100%;  
}

.preview {
  display: flex;
  margin: auto;
  border: solid black 0px;  
  justify-content: center;
  width: 100%;  
  padding-left: 10px;
}

.third {
  display: flex;
  margin: auto;
  border: solid black 0px;  
  justify-content: center;
  width: 33%;
} 

</style>

