<template>
  <div class="content" style="margin-top: 0px;">
      <router-view/>
  </div>   
</template>

<script>
  import { ref } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { router } from 'vue-router';
  import Navbar from './components/Navbar.vue'
  import { rootPath  } from './assets/json/rootPath.json'

  export default {
    components: { Navbar },
    setup() {       
        return { }
    }    
  }
  
</script>