<template>
    <div style="display: flex;margin: auto;justify-content: center;border: solid black 0px" class="w3-margin">
        <img src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jookbox.png?alt=media&token=5b6174a2-b40b-4523-82ac-ff3adebe8f01" style="width: 250px">
    </div>
    <div style="display: flex;margin: auto;justify-content: center;border: solid black 0px">
        <form @submit.prevent="handleSubmit" class="w3-center w-full" style="border: solid black 1px">
            <input type="text" placeholder="Display name" v-model="displayName" class="w3-input w-24rem w3-margin" style="font-size: 20px;">    
            <input type="email" placeholder="Email Address" v-model="email" class="w3-input w-24rem w3-margin" style="font-size: 20px;">
            <input type="password" placeholder="Password" v-model="password" class="w3-input w-24rem w3-margin" style="font-size: 20px;">
            <div v-if="error" class="error">{{ error }}</div>
            <button class="login w3-margin w3-button w3-blue w-10rem" style="font-weight: 600; font-size: 20px;" v-if="!isPending">Signup</button>
            <button v-if="isPending" disabled>Loading</button>
        </form>
    </div>
    <div style="display: flex;margin: auto;justify-content: center">
        <router-link class="w3-btn w3-margin sign-up" :to="{ name: 'Login' }" style="font-size: 20px;">login</router-link>
    </div>
    </template>
    
    <script>
    import useSignup from '@/composables/useSignup'
    import { ref } from 'vue'
    
    export default {
        setup() {
            const { error, signup, isPending } = useSignup()
    
            const displayName = ref('')
            const email = ref('')
            const password = ref('')
    
            const handleSubmit = async () => {
                const res = await signup(email.value, password.value, displayName.value)
                if(!error.value) {
                    console.log('user signed up')
                }
            }
    
            return { email, password, displayName, isPending, error, handleSubmit }
        }
    }
    </script>
    
    <style>
    
    </style>