<template>
    <div style="display: flex;margin: auto;justify-content: center;border: solid black 0px" class="w3-margin">
        <img src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jookbox.png?alt=media&token=5b6174a2-b40b-4523-82ac-ff3adebe8f01" 
        style="width: 250px">
    </div> 
    <div style="display: flex;margin: auto;justify-content: center;border: solid black 0px">
        <form @submit.prevent="handleSubmit" class="w3-center w-full" style="border: solid black 1px">
            <input type="email" placeholder="Email" v-model="email" class="w3-input w-24rem w3-margin" style="font-size: 20px;">    
            <input type="password" placeholder="Password" v-model="password" class="w3-input w-24rem w3-margin" style="font-size: 20px;">
            <div v-if="error" class="error">{{ error }}</div>
            <button class="login w3-margin w3-button w3-blue w-10rem" style="font-weight: 600; font-size: 20px;" v-if="!isPending">Login</button>
            <button v-if="isPending" disabled>Loading</button>
        </form>        
    </div>
    <div style="display: flex;margin: auto;justify-content: center">
        <router-link class="w3-btn w3-margin sign-up" :to="{ name: 'Signup' }" style="font-size: 20px;">Signup</router-link>
    </div>
</template>

<script>
import useLogin from '@/composables/useLogin'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const { error, login, isPending } = useLogin()
        const router = useRouter()

        const email = ref('')
        const password = ref('')

        //const email = 'rita@google.com'
        //const password = 'test@123'

        const handleSubmit = async () => {
            const res = await login(email.value, password.value)
            if(!error.value) {
                console.log('user logged in')
                router.push({ name: 'Home' })
            }            
        }

        return {email, password, handleSubmit, error, isPending}
    }
}
</script>

<style>
  h3 {
    margin-top: 0px;
  }
 .login {    
    padding: 5px 10px 5px 10px ;
 }
</style>